var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-wrapper" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "section-top" },
        [
          _c(
            "BackIndicator",
            {
              nativeOn: {
                click: function($event) {
                  return _vm.backToResults.apply(null, arguments)
                }
              }
            },
            [_vm._v(" Back to search ")]
          ),
          _vm._m(0)
        ],
        1
      ),
      _c("section", { staticClass: "player-details" }, [
        _c("div", { staticClass: "player-details-info" }, [
          _c("div", { staticClass: "inner-wrapper" }, [
            _c("div", { staticClass: "player-img" }, [
              _c("img", {
                attrs: {
                  src:
                    "https://faimages.lcp.uk.com/players/" +
                    _vm.activePlayer.id +
                    ".png"
                }
              })
            ]),
            _c("div", { staticClass: "details-info-inner" }, [
              _c("h3", [_c("span", [_vm._v(_vm._s(_vm.activePlayer.name))])]),
              _c("div", { staticClass: "player-info" }, [
                _c("p", [
                  _vm._v(" Date Of Birth: "),
                  _c("span", { staticClass: "info-highlight" }, [
                    _vm._v(_vm._s(_vm.activePlayer.dateOfBirth))
                  ])
                ]),
                _c("p", [
                  _vm._v(" Age: "),
                  _c("span", { staticClass: "info-highlight" }, [
                    _vm._v(_vm._s(_vm.activePlayer.age))
                  ])
                ]),
                _c("p", [
                  _vm._v(" Club: "),
                  _c("span", { staticClass: "info-highlight display" }, [
                    _vm._v(_vm._s(_vm.activePlayer.club))
                  ])
                ]),
                _c("p", [
                  _vm._v(" League: "),
                  _c("span", { staticClass: "info-highlight" }, [
                    _vm._v(_vm._s(_vm.activePlayer.league))
                  ])
                ]),
                _c("p", [
                  _vm._v(" Position: "),
                  _c("span", { staticClass: "info-highlight" }, [
                    _vm._v(_vm._s(_vm.activePlayer.position))
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "club-id" }, [
              _c("img", {
                attrs: {
                  src:
                    "https://faimages.lcp.uk.com/clubs/" +
                    _vm.activePlayer.clubId +
                    ".png",
                  alt: "clubId"
                }
              })
            ]),
            _vm._m(1)
          ])
        ]),
        _c("div", { staticClass: "player-details-category" }, [
          _c("div", { staticClass: "table-wrapper" }, [
            this.activePlayer.categoryScores.data === null
              ? _c("div", [_vm._v(" No details for this player ")])
              : _c("div", { staticClass: "info-container-content" }, [
                  _c("table", [
                    _vm._m(2),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", [_vm._v("International Appearances")]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.categoryDetailsData.internationalAppearances
                                  .description
                              ) +
                              " "
                          )
                        ]),
                        _c("td", { staticClass: "points-td" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.categoryDetailsData.internationalAppearances
                                  .points
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("Domestic Minutes")]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.categoryDetailsData.domesticMinutes
                                  .description
                              ) +
                              " "
                          )
                        ]),
                        _c("td", { staticClass: "points-td" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.categoryDetailsData.domesticMinutes.points
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("Continental Minutes")]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.categoryDetailsData.continentalMinutes
                                  .description
                              ) +
                              " "
                          )
                        ]),
                        _c("td", { staticClass: "points-td" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.categoryDetailsData.continentalMinutes
                                  .points
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("League Position")]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.categoryDetailsData.leaguePosition
                                  .description
                              ) +
                              " "
                          )
                        ]),
                        _c("td", { staticClass: "points-td" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.categoryDetailsData.leaguePosition.points
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("Continental Progression")]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.categoryDetailsData.continentalProgression
                                  .description
                              ) +
                              " "
                          )
                        ]),
                        _c("td", { staticClass: "points-td" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.categoryDetailsData.continentalProgression
                                  .points
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("League Quality")]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.categoryDetailsData.leagueQuality
                                  .description
                              ) +
                              " "
                          )
                        ]),
                        _c("td", { staticClass: "points-td" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.categoryDetailsData.leagueQuality.points
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("tr", { style: { color: _vm.activePlayer.colour } }, [
                        _c("td", { staticClass: "total " }, [_vm._v("Total")]),
                        _c("td", { staticClass: "total total-responsive" }, [
                          _vm._v(" " + _vm._s(_vm.activePlayer.status) + " ")
                        ]),
                        _c(
                          "td",
                          { staticClass: "points-td total total-responsive" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.activePlayer.totalScore) + " "
                            )
                          ]
                        )
                      ])
                    ])
                  ]),
                  _vm._m(3)
                ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "transfer-lab-top" }, [
      _vm._v(" Powered by "),
      _c("img", {
        staticClass: "logo",
        attrs: { src: "/transferlab_logo.png" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "transfer-lab" }, [
      _vm._v(" Powered by "),
      _c("img", {
        staticClass: "logo",
        attrs: { src: "/TransferLab-White.png" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Category")]),
        _c("th", [_vm._v("Description")]),
        _c("th", [_vm._v("Points")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "foot-note sm-device-fix" }, [
      _c("p", [
        _vm._v(
          " This calculation is based on rules agreed by the FA and the Home Office for players to play in England. There will be edge cases and different interpretations. Our Governing Body Endorsement (GBE) status tool should be used as a guide to assess the likelihood of a player meeting the criteria, rather than a definitive assessment. GBE criteria are regularly reviewed by the FA and Home Office. Any changes will be incorporated into our calculations when they are announced. "
        )
      ]),
      _c("p", [
        _vm._v(
          " N.B. Our calculations don't currently take into account injury data or, for players under 21, any additional points from the youth criteria. There are variations to the GBE criteria for players to play in Scotland and details can be found on the Scottish FA website. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }